<template>
  <button class="ResultItemOpen">
    <ResultItemIcon :icon="mapIcon.open" :size="18" />
  </button>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.ResultItemOpen {
  color: var(--gray-3);
  transition: var(--transition-1);
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  height: 18px;
  border-radius: 3px;
  margin-right: -8px;
  cursor: pointer;

  &:hover {
    color: var(--gray-2);
  }

  &:focus {
    border: 1px solid var(--border-focus);
    outline: 2px solid var(--blue-light);
  }
}
</style>
