<template>
  <div class="ResultItemContent">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.ResultItemContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  line-height: 18px;
  flex: 1;
}
</style>
